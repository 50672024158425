body, p {
    font-family: 'Content', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important;
    overflow: hidden !important;
    line-height: 2rem!important;
}
h1 {
    font-family: 'Tin'!important;
}
.title {
    font-family: 'Tin'!important;
    line-height: 6rem!important;
    font-weight: 600!important;
}
.subtitle {
    line-height: 2.3rem!important;
}
.navbar-wrapper .navbar-item {
    font-family: 'Content',"Roboto",sans-serif!important;
    }
.css-carousel .slides li q {
        font-weight: normal!important;
    }
.im {
    line-height: 1.5!important;
}
.css-carousel .slides li p {
    padding: 10px!important;
}
@media (max-width: 796px) {
.css-carousel {
    height: inherit!important;
}
}
.section .section-title h4 {
    line-height: 2.5rem!important;
}
.shadow-title {
    font-family: "Content","Nexa Light",sans-serif;
    }
.css-carousel .carousel-dots {
        top: 94%!important;
    }
.section-title .title.is-1
    {
        line-height: 4.5rem !important;
    }
.contact-page-form H1.title
    {
        line-height: 3.5rem !important;
    }
.section-title-wrapper .section-title-landing {
        font-family: "Content","Nexa Light",sans-serif !important;
        line-height: 4.5rem !important;
    }